<div class="loading-container" *ngIf="loadingProduct; else dataLoaded">
  <nz-spin nzSize="large"></nz-spin>
</div>

<ng-template #dataLoaded>
  <div class="container" *ngIf="campaignProductAvailable">
    <div nz-row>
      <div nz-col nzSpan="24">
        <nz-tabset [nzSelectedIndex]="selectedIndex" (nzSelectedIndexChange)="onTabChange($event)">
          <nz-tab *ngFor="let tab of tabs" [nzTitle]="tab.name">
            <div class="spinner" *ngIf="loading; else notloading">
              <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
            </div>
          </nz-tab>
        </nz-tabset>
        <div class="image-holder" *ngIf="!filteredSmsCampaigns.length && !loading">
          <img src="../../../assets/images/listings-empty.png" />
          <h2 class="create-title mar-zero">Promote your SMS Campaigns with Style!</h2>
          <p class="sub-title">Select from our multiple SMS templates, schedule it out, add your audience, and watch it
            re-engage your prospects!
          </p>
          <button nz-button nzType="primary" (click)="newCampaign();">Let's Go</button>
        </div>
        <ng-template #notloading>
          <div class="title-header" *ngTemplateOutlet="WebsiteContent"></div>
          <ng-container *ngTemplateOutlet="sites"></ng-container>
        </ng-template>
      </div>

    </div>
  </div>

  <div nz-row *ngIf="!campaignProductAvailable && !loading">
    <div nz-col nzSpan="24">
      <div class="social-connect-container">
        <div class="social-headings">
          <h2>Upgrade Your Plan</h2>
          <p>Schedule a time for your Account Manager to call and upgrade your plan for you!</p>
        </div>
        <div class="preview">
          <iframe src="https://calendly.com/client-success-call/success-call" width="100%" height="600" frameborder="0"
            allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>

  <ng-template #sites>
    <ng-container *ngFor="let campaign of filteredSmsCampaigns">
      <div nz-row>
        <div nz-col nzSpan="24">
          <nz-card style="margin-bottom: 16px;">
            <!-- First part: Campaign details -->
            <div nz-row>
              <div nz-col nzSpan="2">
                <img class="speaker-phone" src="../../../assets/icons/megaphone.svg">
              </div>
              <div nz-col nzSpan="6">
                <h4>{{ campaign.name }}</h4>
                <p>{{ timeDifference(campaign.created_at) }}</p>
              </div>
              <div nz-col nzSpan="16">
                <div nz-row style="justify-content: end;">
                  <div nz-col nzSpan="4">
                    <p class="stat-color">Status</p>
                    <p>{{campaign.status}}</p>
                  </div>
                  <div nz-col nzSpan="4">
                    <p class="stat-color">Start Date Time</p>
                    <p>{{ campaign.start_date_time | date:"MMM dd, yyyy 'at' hh:mm a" }}</p>
                  </div>
                  <div nz-col nzSpan="2" style="text-align: right;">
                    <a nz-dropdown [nzDropdownMenu]="menu1" nzPlacement="bottomRight">
                      <img class="ellipsis-vertical" src="../../../assets/icons/ellipsis-vertical.svg">
                    </a>
                    <nz-dropdown-menu #menu1="nzDropdownMenu">
                      <ul nz-menu>
                        <li nz-menu-item (click)="editCampaign(campaign.id)">Edit</li>
                        <li nz-menu-item (click)="deleteCampaign(campaign.id)">Delete</li>
                      </ul>
                    </nz-dropdown-menu>
                  </div>
                </div>
              </div>
            </div>
          </nz-card>
        </div>
      </div>
    </ng-container>
    <div nz-row nzType="flex" nzJustify="end" style="margin-top: 30px;" *ngIf="!loading">
      <nz-pagination [nzPageIndex]="page" [nzTotal]="pageInfo.total_entries" nzPageSize="10"
        (nzPageIndexChange)="didPage($event)"></nz-pagination>
    </div>
  </ng-template>

  <ng-template #WebsiteContent>
    <div nz-row class="pdb-16">
      <div nz-col nzSpan="20"></div>
      <div nz-col nzSpan="4" class="padding-top-col text-end">
        <button nz-button nzType="primary" (click)="newCampaign();">
          <i nz-icon nzType="plus" nzTheme="outline"></i>
          New Campaign
        </button>
      </div>
    </div>
  </ng-template>
</ng-template>

