import { Component, OnInit, ChangeDetectorRef, OnDestroy, HostListener, AfterViewInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router, NavigationStart } from '@angular/router';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Customer } from '../../vos/customer/customer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { CustomersService } from '../../services/customers/customers.service';
import { GenericDialogComponent } from '../generic-dialog/generic-dialog.component';
import { OnboardingService } from '../../services/onboarding/onboarding.service';
import { GlobalsService } from '../../services/globals/globals.service';
import { ProductsService } from '../../services/products/product-api.service';
import { environment } from '../../../environments/environment';
import { CustomersGoogleService } from '../../services/customers-google/customers-google.service';
import { FacebookOnboardingDialogComponent } from '../../shared/facebook-onboarding-dialog/facebook-onboarding-dialog.component';
import { NzMessageService } from 'ng-zorro-antd/message';

import { Observable } from 'rxjs';
import { Subscription } from '../../vos/subscription/subscription';
import {GhlService} from '../../services/ghl/ghl.service';
import { GenericDialogConfig } from 'src/app/models/interfaces/modals/generic-modal-config';
import { IModalData } from 'src/app/models/interfaces/modals/modal';
declare const $zopim;
const navSidebarItems: any = [
  {
    title: 'Home',
    path: '/',
    icon: 'home',
    color: '#30d289',
    selectedRoutes: []
  },

  {
    title: 'Markets',
    path: '/my_markets',
    icon: 'market',
    color: '#18a0b2',
    selectedRoutes: ['my_markets', 'market_report']
  },
  {
    title: 'Listings',
    path: '/listings',
    icon: 'listing',
    color: '#18a0b2',
    selectedRoutes: ['listing']
  },
  {
    title: 'Testimonials',
    path: '/testimonials',
    icon: 'testimonials',
    color: '#30d289',
    selectedRoutes: []
  },
  {
    title: 'Content',
    path: '/content',
    icon: 'content',
    color: '#30d289',
    selectedRoutes: []
  },
  // {
  //   title: 'Content Calendar',
  //   path: '/content/calendar',
  //   icon: 'calender',
  //   color: '#30d289',
  //   selectedRoutes: []
  // }
];
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({ width: '*' })),
      state('false', style({ width: '61px' })),
      transition('false => true', animate('.5s ease-in')),
      transition('true => false', animate('.5s ease-out')),
    ]),
  ],
})
export class SidebarComponent implements OnInit, OnDestroy {
  topHeadingWithIcon = {
    title: 'Home',
    icon: 'home',
  };
  opened = true;
  animating = false;
  isCollapsed = false;
  mobileQuery: MediaQueryList;
  customer: Customer;
  googleLink;
  loading = false;
  visible = false;
  newsArticle: any;
  isGoogleAccountConnected = false;
  resources: any;
  crm: any;
  outreach: any;
  timerStyle = {
    color: 'white',
    fontSize: '18px',
    fontFamily: 'Montserrat',
  };
  private _mobileQueryListener: () => void;
  emailNav;
  conversationProduct;
  products;

  navItems: any = [...navSidebarItems];
  profileMenuItems: { text: string, action: (() => void) | string, icon: string }[] = [
    { text: 'Account', action: '/team', icon: 'team' },
    { text: 'Settings', action: '/settings', icon: 'setting' },
    { text: 'Profile', action: '/profile', icon: 'user' },
    { text: 'Need Help?', action: (() => this.goToLink('https://calendly.com/client-success-call/success-call')), icon: 'question-circle' },
    { text: 'Call Now', action: (() => window.location.href = 'tel:(310) 997-4142'), icon: 'phone' },
    { text: 'Terms & Conditions', action: '/terms-conditions', icon: 'snippets' },
    { text: 'Logout', action: (() => this.logout()), icon: 'logout' }
  ];

  config = {
    paddingAtStart: true,
    interfaceWithRoute: true,
    classname: 'mnav-dropdown',
    listBackgroundColor: '#28484D',
    fontColor: `#FFFFFF`,
    backgroundColor: '#28484D',
    selectedListFontColor: `#FFFFFF`,
    highlightOnSelect: true,
    collapseOnSelect: true,
    rtlLayout: false,
  };
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public router: Router,
    private authService: AuthenticationService,
    private customerService: CustomersService,
    private onboardingService: OnboardingService,
    private modalService: NzModalService,
    private globalsService: GlobalsService,
    private productsService: ProductsService,
    private customersGoogleService: CustomersGoogleService,
    private ghlService: GhlService,
    private message: NzMessageService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.opened = !this.mobileQuery.matches;
    // this.fbService?.init({
    //   appId: '210551162312621',
    //   xfbml: true,
    //   version: 'v2.8',
    // });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.opened = !this.mobileQuery.matches;
  }

  ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  fetchCustomerProducts() {
    this.loading = true;
    const params: Record<string, any> = {
      per_page: 40,
    };
    if (!this.customer) {
      this.authService.logout();
      this.router.navigate(['/login']);
      return;
    }
    this.productsService
      .productList(`customers/${this.customer?.id}/products`, params)
      .subscribe(
        (res) => {
          if (res) {
            const data = res.data;
            this.products = res.data;
            const emailProduct = data ? data?.find((p) => p.abbreviation === 'PML') : null;
            const reelProduct = data ? data?.find((p) => p.abbreviation === 'REEL') : null;
            if (emailProduct && emailProduct != null && !emailProduct.locked && this.customer.test) {
              this.globalsService.setIsEmailCampaignAllowed(true);
              this.emailNav = {
                title: 'Email',
                path: null,
                icon: 'mail',
                color: '#18a0b2',
                selectedRoutes: ['email', 'contacts'],
                subNavs: [
                  {
                    title: 'Campaigns',
                    path: '/email_campaigns',
                    icon: 'group',
                    color: '#18a0b2',
                    selectedRoutes: ['email_campaigns'],
                  },
                  {
                    title: 'Lists',
                    path: '/contacts/list',
                    icon: 'unordered-list',
                    color: '#18a0b2',
                    selectedRoutes: ['contacts'],
                  },
                  {
                    title: 'Settings',
                    path: '/email_campaigns/settings',
                    icon: 'setting',
                    color: '#18a0b2',
                    selectedRoutes: ['email_campaigns'],
                  },
                ],
              };
            }
            if (reelProduct && reelProduct != null && !reelProduct.locked) {
              this.setReelsProduct();
            }
            // gogole product
            const googleProduct = data ? data?.find((p) => p.abbreviation === 'GMB') : null;
            if (googleProduct && googleProduct != null && googleProduct?.tier === 'ultra') {this.globalsService.setIsGMBAllowed(true); }
            this.loading = false;
          }
        },
        (err) => {
          this.loading = false;
        }
      );
  }

  checkCustomerConectedGoogleBusiness() {
    this.customersGoogleService.getGoogleUserConnection().subscribe((res) => {
      if (res && res.body?.data?.length > 0) {
        this.isGoogleAccountConnected = true;
      }
    });
  }

  ngOnInit() {
    // this.getOnbaording();
    this.setHeadingWithIcon();
    this.checkCustomerConectedGoogleBusiness();
    this.authService.currentCustomer.subscribe((c) => {
      if (c && c.settings.show_price === undefined) {
        c.settings.show_price = true;
      }
      if (c) {
        this.customer = c;
      }
    });
    if (this.customer.render_only){
      this.setShortSidebarMenu();
    } else{
      this.setSidebarMenu();
      this.setNewsAndArticles();
      this.setOutreach();
    }
  }

  setShortSidebarMenu(){
   const navItems =  [
      {
        title: 'Home',
        path: '/',
        icon: 'home',
        color: '#30d289',
        selectedRoutes: []
      },
      {
        title: 'Markets',
        path: '/my_markets',
        icon: 'market',
        color: '#18a0b2',
        selectedRoutes: ['my_markets', 'market_report']
      },
      {
        title: 'Listings',
        path: '/listings',
        icon: 'listing',
        color: '#18a0b2',
        selectedRoutes: ['listing']
      },
     {
       title: 'Testimonials',
       path: '/testimonials',
       icon: 'testimonials',
       color: '#30d289',
       selectedRoutes: []
     },
      {
        title: 'Content',
        path: '/content',
        icon: 'content',
        color: '#30d289',
        selectedRoutes: []
      },
    ];
   this.navItems = navItems;
  }
  setSidebarMenu(){
    const navItems = [
      {
        title: 'Websites',
        path: '/websites',
        icon: 'websites',
        color: '#18a0b2',
        selectedRoutes: ['websites'],
      },
      {
        title: 'MarketPlace',
        path: '/marketplace',
        icon: 'marketplace',
        color: '#18a0b2',
        selectedRoutes: ['websites'],
      },
      {
        title: 'CRM',
        path: '/crm',
        icon: 'crm',
        color: '#18a0b2',
      }
    ];
    this.navItems = [...navSidebarItems, ...navItems];
    // const tipNavItem = {
    //   title: 'Marketing Tips',
    //   path: '/tips',
    //   icon: 'notification',
    // };
    // this.navItems.push(tipNavItem);
    this.routeEvent(this.router);
    this.fetchCustomerProducts();
  }

  setNewsAndArticles() {
    // this.newsArticle = {
    //   title: 'News & Articles',
    //   path: null,
    //   icon: 'read',
    //   color: '#18a0b2',
    //   categories: [
    //     {
    //       title: 'General',
    //       path: '/news',
    //       icon: 'read',
    //     },
    //     {
    //       title: 'Celeb & Luxury',
    //       path: '/celeb_luxury',
    //       icon: 'crown',
    //     }
    //   ],
    // };

    this.resources = {
      title: 'Resources',
      path: null,
      icon: 'resources',
      color: '#18a0b2',
      categories: [
        {
          title: 'Marketing Tips',
          path: '/tips',
          icon: 'tips',
        },
        {
          title: 'News & Articles',
          path: '/news',
          icon: 'news',
        }
      ],
    };
  }

  setOutreach() {
    // this.outreach = {
    //   title: 'Outreach',
    //   path: null,
    //   icon: 'read',
    //   color: '#18a0b2',
    //   categories: [
    //     {
    //       title: 'Conversations',
    //       path: '/conversations',
    //       icon: 'wechat',
    //     },
    //     {
    //       title: 'SMS Campaigns',
    //       path: '/campaigns',
    //       icon: 'send',
    //       color: '#30d289',
    //     },
    //     {
    //       title: 'AI Templates',
    //       path: '/templates',
    //       icon: 'bars',
    //       color: '#30d289',
    //     }
    //   ],
    // };
  }

  setConversationProduct(){
    const conversation =  {
      title: 'Conversations',
      path: '/conversations',
      icon: 'wechat',
    };
    this.navItems.push(conversation);
  }


  setReelsProduct(){
    // const reels =  {
    //   title: 'Reels',
    //   path: '/reels',
    //   icon: 'reels',
    // };
    // this.navItems.push(reels);
  }

  setHeadingWithIcon() {
    const path = this.router.url;
    this.navItems.forEach(element => {
      if (element.path === path) {
        this.topHeadingWithIcon.title = element.title;
        this.topHeadingWithIcon.icon = element.icon;
      }
    });
  }


  getOnbaording() {
    this.onboardingService.loadOnboardingSteps().subscribe((res) => {});
  }

  goto(nav) {
    if (nav.path) {
      this.router.navigateByUrl(nav.path);
      this.topHeadingWithIcon.title = nav.title;
      this.topHeadingWithIcon.icon = nav.icon;
    }
  }
  toggle() {
    this.opened = !this.opened;
  }
  start() {
    this.animating = true;
    this.tick();
  }

  done() {
    this.animating = false;
  }
  hidePopOver(): void {
    this.visible = false;
  }
  tick() {
    if (this.animating) {
      requestAnimationFrame(() => this.tick());
    }
  }
  goHome() {
    this.router.navigate(['/']);
  }
  logout() {
    this.customerService.logout().subscribe((response) => {
      this.authService.logout();
      this.router.navigate(['/logout']);
    });
  }
  showHelp() {
    const modal = this.modalService.create<GenericDialogComponent, GenericDialogConfig>({
      nzContent: GenericDialogComponent,
      nzData: {
        content: {
          type: 'video',
          url: 'http://s3-us-west-1.amazonaws.com/universal-promote/TutorialVideo_Final_010.mp4',
          autoplay: true,
        },
        hideButtons: true,
      },
      nzFooter: null,
      nzWidth: '70%',
      nzClassName: 'video-modal',
    });
  }

  routeEvent(router: Router) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.opened = !this.mobileQuery.matches;
      }
    });
  }

  handleMenuAction(action: (() => void) | string) {
    this.visible = false;
    if (action) {
      if (typeof action === 'string') {
        this.router.navigateByUrl(action);
      } else {
        action();
      }
    }
  }

  help() {
    window.open('https://calendly.com/dashboard-discovery/with-amber', 'nil');
  }

  get stepsCompltionPercent(): any {
    return this.onboardingService.stepsCompltionPercent();
  }

  get showWelcome(): boolean {
    return !!(
      this.customer &&
      // && this.customer.brokerage
      this.customer.logo &&
      this.customer.logo.original &&
      this.customer?.image &&
      this.customer?.image.original &&
      this.onboardingService.onboardingSteps &&
      this.onboardingService.onboardingSteps.length &&
      this.onboardingService.onboardingSteps[0].completed
    );
  }

  get sidebarWidth() {
    if (this.mobileQuery.matches) {
      return 0;
    } else if (this.isCollapsed) {
      return 80;
    } else {
      return 251;
    }
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  isSelected(nav) {
    return (
      nav.path === this.router.url ||
      (nav.length &&
        nav.selectedRoutes.find((r) => this.router.url.includes(r)))
    );
  }

  createContent() {
    this.router.navigateByUrl('/create_content');
    this.topHeadingWithIcon.title = 'Create Content';
    this.topHeadingWithIcon.icon = 'video-camera-add';
  }


  connectFacebook() {
    const modal = this.modalService.create<FacebookOnboardingDialogComponent, IModalData>({
      nzContent: FacebookOnboardingDialogComponent,
      nzData: {
        config: {
          autoConnect: true,
          isReAuth: false,
        },
      },
      nzFooter: null,
      nzWidth: '50%',
    });
    modal.afterClose.subscribe((response) => {
      this.authService.refresh();
    });
  }

  onGoogleIconClick(event: Event): void {
    event.preventDefault();

    if (this.customer.google_business_url) {
      window.open(this.customer.google_business_url, '_blank');
    } else {
      this.message.create('info', 'Google account is connected but no location maps uri found');
    }
  }

  get showGMB() {
    return this.globalsService.getIsGMBAllowed();
  }
  toggleCollapse() {
    if (this.isCollapsed) {
      this.isCollapsed = false; // Collapse it
    } else {
      this.isCollapsed = true;  // Expand it
    }
    // Manually trigger change detection
    this.changeDetectorRef.detectChanges();
  }
}
