import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';

import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import {NzModalRef} from 'ng-zorro-antd/modal';
import {NpsScoreService} from '../../services/nps-score/nps-score.service';
import {ReferralsService} from '../../services/referrals/referrals.service';
@Component({
  selector: 'app-referral-form',
  templateUrl: './referral-form.component.html',
  styleUrls: ['./referral-form.component.scss'],
  animations: [
    trigger('flyInOut', [
      // state('void', style({ opacity: 0 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s')
      ]),
      transition(':leave',
        animate('0.5s', style({ opacity: 0 })))
    ])
  ]
})
/**
 * Blank view.
 */
export class ReferralFormComponent {


  giftType = '';
  referred_by = '';
  completed = false;
  referrals = [{
    name: null,
    phone: null,
    email: null,
    error: null,
    gift_type:  this.giftType,
    referred_by:  this.referred_by
  }];


  /**
   * Component constructor and DI injection point.
   */
  constructor(
    // private modalRef: NzModalRef,
    private npsService: NpsScoreService,
    private referralsService: ReferralsService,
    private messageService: NzMessageService,
    private router: Router,
  ) {
  }

  handleSubmitReferral(){
    const referralsWithMissingContact = [];

    this.referrals.forEach( ref => {
      if (!ref.phone && !ref.email) {
        referralsWithMissingContact.push(ref);
        // this.messageService.create('error', 'Please add missing information! Phone or email is required!');
      }
      ref.gift_type = this.giftType;
      ref.referred_by = this.referred_by;
    });
    if (referralsWithMissingContact.length > 0) {
      this.messageService.create('error', 'Please add missing information! Phone or email is required!');
      return true;
    }
    this.referrals.forEach(referral => {
      this.referralsService.createWithoutCustomer(referral).subscribe(res => {
        this.completed = true;
      });
    });
    // this.modalRef.destroy();
  }

  handleCancel() {
    // this.modalRef.destroy();
  }

  addRef(){
    this.referrals.push({
      name: null,
      phone: null,
      email: null,
      error: null,
      gift_type:  this.giftType,
      referred_by:  this.referred_by
    });
  }

  removeRef(i){
    this.referrals.splice(i, 1);
  }
  navigateToReferrals(): void {
      window.location.href = '/referral';
  }

}
