<nz-tabset nzTabPosition="left">
  <nz-tab
    *ngFor="let tab of tabs"
    [nzTitle]="transform(tab.name)">
    <div>
<!--      <br>-->
      <div class="mt-25" *ngIf="tab.styles.length > 0">
        <div nz-row>
          <div nz-col class="paddingleftCustom">
            <button nz-button nzType="primary" (click)="resetDefaults(tab)">Reset Defaults</button>
          </div>
        </div>
        <br>
        <div nz-row>
          <div nz-col class="gutter-row space-right" nzSpan="10" *ngFor="let style of tab.styles; index as i">
            <app-select-image-card
              [content]="style"
              [index]="i"
              [width]="'100%'"
              [isCoverPhotos]="isCoverPhotos"
              [horizontalMargin]="'0px'"
              (cardClicked)="selectStyle(style, tab.styles)"
              [disableDeselect]="true"
              fxFlex>
            </app-select-image-card>
          </div>
        </div>
      </div>
    </div>
  </nz-tab>
</nz-tabset>
