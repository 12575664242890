import { Injectable } from '@angular/core';
import { CustomerResourceService } from '../customer-resource.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../authentication/authentication.service';
import { Topic } from 'src/app/vos/topic/topic';
import { environment } from 'src/environments/environment';
import {Observable, of} from 'rxjs';
import {DataResponse} from '../../models/data-response/data-response';
import {map} from 'rxjs/operators';
@Injectable()

export class CustomerProductStylesService extends CustomerResourceService<any> {

  public endpoint = 'customer_content_calendar_styles';
  public data_key = 'customer_content_calendar_style';

  public _urls = `${environment.api_url}customers`;

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Topic, http, authService);
  }


  reset(product){
    const url = `${this._customerURI}${this.customerEndpoint}/reset_styles.json?abbreviation=${product}`;
    const payload = JSON.stringify({customer_content_calendar_style: { abbreviation: product }});
    return this.http.get<any>(
      url
    );
  }

}
