import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Content } from 'src/app/vos/content/content';
import { CustomerResourceService } from '../customer-resource.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { environment } from 'src/environments/environment';
import {Subject} from 'rxjs';
import {DataResponse} from '../../models/data-response/data-response';
import {map} from 'rxjs/operators';

/**
 * Config class to be wired into an injector.
 * @see CoreModule#forRoot
 * @see https://angular.io/guide/dependency-injection#optional-dependencies
 */

@Injectable()
export class ReferralsService extends CustomerResourceService<Content> {
  public endpoint = 'referrals';
  public data_key = 'referral';
  private subject = new Subject<any>();
  public _urls = `${environment.api_url}customers`;

  constructor(http: HttpClient, authService: AuthenticationService) {
    super(Content, http, authService);
  }

  createWithoutCustomer(value: any): Observable<any> {

    const payload = JSON.stringify(value); // Ensure JSON parsing

    return this.http
      .post<any>(`${environment.api_url}referrals/public`, payload, {
        headers: { 'Content-Type': 'application/json' }, // Standard JSON header
      })
      .pipe(map(resp => new this.resource(resp)));
  }

}
