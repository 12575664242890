import { Component, EventEmitter, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActionCableService, Channel } from 'angular2-actioncable';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';

import { ContentPreviewDialogComponent } from '../../../shared/content-preview-dialog/content-preview-dialog.component';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { NotificationService } from '../../../services/notification/notification.service';
import { environment } from '../../../../environments/environment';
import { IModalData } from 'src/app/models/interfaces/modals/modal';

@Component({
  selector: 'app-content-preview-item',
  templateUrl: './content-preview-item.component.html',
  styleUrls: ['./content-preview-item.component.scss']
})
export class ContentPreviewItemComponent implements OnInit, OnDestroy {
  @Input()
  content;
  @Input()
  previewWidth = 138;
  @Input()
  textLimit = 40;
  @Output()
  onClose = new EventEmitter();

  subscription: Subscription;
  subInterval;
  refreshing = false;
  constructor(
    private notificationService: NotificationService,
    private cableService: ActionCableService,
    private authService: AuthenticationService,
    private modalService: NzModalService,
    private router: Router,
  ) { }

  ngOnInit() {
    const customer = this.authService.currentCustomerValue;
    const customer_id = customer.id;
    const channel: Channel = this.cableService.cable(environment.wss_url).channel('ContentChannel', { content_id: this.content.id });
    // Subscribe to incoming messages
    if (this.content.status === 'rendering') {
      this.subscription = channel.received().subscribe(res => {
        if (res) {
          const content = res.data;
          this.refreshing = true;
          setTimeout(() => {
            this.content.url = content.url;
            this.content.status = 'ready';
            this.content.thumbnail = content.thumbnail;
            this.content.caption = content.caption;
            this.refreshing = false;
            this.clearSubscriptions();
          }, 1000);
        }
      });

      this.subInterval = setInterval(function () {
        channel.
          perform('ping', {
            message: { "event": "ping" }
          });
      }, 30000);
    }

  }

  showPreview(content) {
    this.modalService.closeAll();
    const modal = this.modalService.create<ContentPreviewDialogComponent, IModalData>({
      nzTitle: content.display_name,
      nzContent: ContentPreviewDialogComponent,
      nzData: {
        config: {
          content
        }
      },
      nzFooter: null,
      nzWidth: '50%',
      // nzBodyStyle: {
      //   'height': '65vh',
      //   'overflow': 'scroll'
      // }
    });
    modal.afterClose.subscribe(response => {
      this.onClose.emit(null);
      this.router.navigate([], {
        queryParams: null
      });
    });
  }

  truncateChar(text: string): string {
    const charlimit = this.textLimit;
    if (!text || text.length <= charlimit) {
      return text;
    }
    const shortened = text.substring(0, charlimit) + '...';
    return shortened;
  }

  clearSubscriptions() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      clearInterval(this.subInterval);
    }
  }
  ngOnDestroy() {
    this.clearSubscriptions();
  }

  get playButtonPosition() {
    let position = { top: '21px', left: '52px' };
    if (this.previewWidth > 200) {
      position = { top: '85px', left: '180px' };
    }
    return position;
  }

}
