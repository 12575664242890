import { CardButtonConfig, CardColors } from 'src/app/models';
import { Resource } from '../resource/resource';
import { productFormFields } from './form_fields';
import { Listing } from '../listing/listing';
import { Customer } from '../customer/customer';
import { Content } from '../content/content';
import { MarketReport } from '../market-report/market-report';
import { GenericDialogConfig } from 'src/app/models/interfaces/modals/generic-modal-config';

export class ProductStyle {
  id: string;
  product_id: string;
  name: string;
  effective_name: string;
  preview: string;
  selected?: boolean;
  created_at: string;
  updated_at: string;
  image?: string;
  ratio?: string;
  is_vertical?: boolean;
  tag_names?: string[];
  is_default_for_calendar?: boolean;
}
export class Product extends Resource {
  id: string;
  name: string;

  category: string;

  available: boolean;
  featured?: boolean;
  rank?: number;
  locked: boolean;

  abbreviation: string;

  parent_type: string[];
  icon: string;
  image: string;
  path: string;
  // isAvailable: boolean;
  styles: { name: string; preview: string, thumb?: string, filetype?: string }[];
  actions: { name: string; action: string }[];

  content?: Content[];

  external_link?: string;
  description?: string;
  media_type?: string;

  get requiredFields(): string[] {
    return this.name in productFormFields
      ? productFormFields[this.name].required
      : [];
  }
  get shownFields(): string[] {
    return this.name in productFormFields
      ? productFormFields[this.name].shown
      : [];
  }
  get normalizedName(): string {
    return this.name
      .split('_')
      .join(' ')
      .capitalizeAll();
  }

  get fa_config(): string[] {
    return this.icon.split(' ');
  }

  constructor(vals: any) {
    super(vals);
  }
  creatLinkForProduct(product, forItem, resourceName, routeName?) {
    if (product.name === 'listing_recap_video') {
      return 'create_content/listings/listing_recap_video/new';
    } else if (product.name === 'cover_photos') {
      return routeName ? `create_content/${routeName}/cover_photos/new` : 'create_content/cover_photos/new';
    } else if (product.name === 'banner_videos') {
      return routeName ? `create_content/${routeName}/banner_videos/new` : 'create_content/banner_videos/new';
    } else if (product.name === 'marketing_videos') {
      return forItem ? 'marketing_videos' : ( routeName ? `create_content/${routeName}/marketing_videos/new` : 'create_content/marketing_videos/new');
    } else if ((forItem && forItem.constructor.name === 'MarketReport' && resourceName === 'marketing_videos') ||
      (forItem && forItem.constructor.name === 'Listing' && product.name === 'email_campaigns')) {

      return this.path.includes(':id') ? product.path?.replace(':id', forItem.id) : `create_content/${forItem.id}/${product.path}`;
    } else if (forItem && forItem.constructor.name !== 'Customer' && (product.parent_type.includes('market_report') ||
      product.parent_type.includes('listing')) && this.path.includes(':id')) {
      product.path = product.path?.replace('listing', 'create_content');
      product.path = product.path?.replace('market_report', 'create_content');

      return product.external_link ? product.external_link : product.path?.replace(':id', forItem.id);
    } else if (forItem && forItem.constructor.name === 'MarketReport' && resourceName === 'market_reports') {
      return `${resourceName}/${forItem.id}/${product.path}`;
    } else if ((product.parent_type.includes('market_report')
      || product.parent_type.includes('listing'))
      && this.path.includes(':id')) {
      // product.path.replace('listing', 'create_content');
      // product.path.replace('market_report', 'create_content');
      // create a route in dashboard routing => select-resource/:product_name. select listing or market report on the base of parent type
      return product.external_link ? product.external_link : (routeName ? `create_content/${routeName}/${product.name}` : `create_content/${product.name}`);
    } else if(this.name.includes('testimonial')){
      return product.external_link ? product.external_link : (routeName ? `create_content/${routeName}/${product.name}` : `create_content/${product.name}`);

    } else if (product.name === 'websites') {
      if (forItem) {
        return `websites/new/${resourceName}s/${forItem.id}`;
      } else {
        return `websites/new`;
      }
    } else {
      return product.external_link ? product.external_link : (routeName ? `create_content/${routeName}/${this.path}` : `create_content/${this.path}`);
    }
  }

  creatParamsForProduct(product, forItem, resourceName) {
    if ((forItem && forItem.constructor.name === 'MarketReport' && resourceName === 'marketing_videos') ||
      (forItem && forItem.constructor.name === 'Listing' && product.name === 'email_campaigns')) {
      return { type: resourceName }
    } else if (forItem && forItem.constructor.name !== 'Customer' && (product.parent_type.includes('market_report') ||
      product.parent_type.includes('listing')) && this.path.includes(':id')) {
      product.path?.replace('listing', 'create_content');
      product.path?.replace('market_report', 'create_content');
      return { type: resourceName }
      // return product.external_link ? product.external_link : product.path.replace(':id', forItem.id);
    } else if(this.name.includes('testimonial')){
      return { type: this.name }
    } else if ((product.parent_type.includes('market_report')
      || product.parent_type.includes('listing'))
      && this.path.includes(':id')) {
      // create a route in dashboard routing => select-resource/:product_name. select listing or market report on the base of parent type
      return { type: product.parent_type[0] }
    } else {
      return {}
    }
  }

  cardColors(resourceName): CardColors {
    switch (resourceName) {
      case 'listing':
        return {
          bg: this.available ? '#FFFFFF' : 'darkgray',
          text: '#000000',
          textSelected: '#000000'
        };
      case 'my_markets':
        return {
          bg: this.available ? '#30d289' : 'darkgray',
          text: 'white',
          textSelected: 'white'
        };
      case 'customer':
        return {
          bg: this.available ? '#30d289' : 'darkgray',
          text: 'white',
          bgSelected: '#18754c',
          textSelected: 'white'
        };
      case 'marketing':
        return {
          bg: this.available ? '#30d289' : 'darkgray',
          text: 'white',
          bgSelected: '#18754c',
          textSelected: 'white'
        };
      case 'branding':
        return {
          bg: this.available ? '#30d289' : 'darkgray',
          text: 'white',
          bgSelected: '#18754c',
          textSelected: 'white'
        };
      default: return {
        bg: this.available ? '#30d289' : 'darkgray',
        text: 'white',
        bgSelected: '#18754c',
        textSelected: 'white'
      };
    }
  }

  messageForState(state: string): string {
    switch (state) {
      case 'Coming Soon':
        return `${this.normalizedName
          } is not ready yet.\nPlease contact us if you have any questions.`;
      case 'Unavailable':
        return `${this.normalizedName
          } is not available to your current plan.\nPlease contact us to upgrade.`;
      case 'Upgrade':
        return `${this.normalizedName
          } is not available on your current plan.\nPlease upgrade to gain access.`;
      default:
        return '';
    }
  }
  linksForUnavailableState(state: string): { label: string, href: string, icon: string, color: string }[] {
    switch (state) {
      case 'Coming Soon':
      case 'Unavailable':
        return [
          {
            label: 'Call Us',
            href: 'tel:1-888-343-0940',
            icon: 'fas phone',
            color: '#30d289'
          },
          {
            label: 'Email Us',
            href: 'mailto:support@zentap.com',
            icon: 'fas envelope',
            color: '#30d289'
          }
        ];
      case 'Upgrade':
        return [
          {
            label: 'Upgrade',
            href: '/upgrade',
            icon: 'fas briefcase',
            color: '#30d289'
          }
        ];
      default:
        return [];
    }
  }
  unavailableConfig(customer: Customer): GenericDialogConfig {
    const unavailableState = !this.available ? 'Coming Soon' : customer && customer.billing_provider === 'dpp' ? 'Unavailable' : 'Upgrade';
    if (!this.available) {
      return {
        title: unavailableState,
        message: this.messageForState(unavailableState),
        links: this.linksForUnavailableState(unavailableState),
        buttonLabel: 'Ok'
      };
    }
  }
  public cardButton(customer: Customer, resourceName: string, forItem?: Resource, routeName?: string): CardButtonConfig {
    return {
      data_id: this.id,
      id: this.name,
      title: this.normalizedName,
      actionText: this.available ? this.category.capitalizeAll() : 'Coming Soon',
      link: this.creatLinkForProduct(this, forItem, resourceName, routeName),
      queryParams: this.creatParamsForProduct(this, forItem, resourceName),
      icon: this.icon,
      image: this.image,
      unavailable: this.available && this.locked ? false : this.unavailableConfig(customer),
      colors: this.cardColors(resourceName),
      buttonConfigs: null,
      description: this.description,
      isSkeleton: false
    } as CardButtonConfig;
  }
}
