import {Component, OnInit, Input, ElementRef, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import { Content } from 'src/app/vos/content/content';
import { Customer } from 'src/app/vos/customer/customer';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import {CustomerProductStylesService} from '../../services/customer-product-styles/customer-product-styles.service';
import {NzTabPosition} from 'ng-zorro-antd/tabs';
import {NzMessageService} from 'ng-zorro-antd/message';

@Component({
  selector: 'app-customer-product-styles',
  templateUrl: './customer-product-styles.component.html',
  styleUrls: ['./customer-product-styles.component.scss']
})
export class CustomerProductStylesComponent implements OnInit {

  @Output()
  changeStyle = new EventEmitter<any>();

  tabs;
  nzTabPosition: NzTabPosition = 'top';
  selectedIndex = 27;
  @Input()
  content: Content;

  @Input()
  index: any;

  customer: Customer;

  @Input()
  width = 'auto';

  @Input()
  horizontalMargin = '25px';

  @Input()
  verticalMargin = '25px';

  @Input()
  caption_editable = false;

  @Input()
  isCoverPhotos = false;

  @Input()
  disableDeselect = false;

  editing = false;
  loading = false;
  initialWidth: string;
  canPost = false;
  elem: ElementRef;

  @Output() cardClicked = new EventEmitter();
  filteredStyles: any;

  get height(): string {
    if (this.width === 'auto') {
      return 'auto';
    } else {
      return '' + parseInt(this.width?.replace(/\D/g, ''), 10) * 296 / 282 + 'px';
    }
  }
  constructor(
    private authService: AuthenticationService,
    private customerProductStylesService: CustomerProductStylesService,
    private message: NzMessageService,
    private cdr: ChangeDetectorRef,
    element: ElementRef
  ) {
    this.elem = element;
  }

  ngOnInit() {
    this.authService.currentCustomer.subscribe((c) => {
      if (c) {
        this.customer = c;
      }
    });
    this.getStyles();
    if (this.index && this.index === 0){
      this.cardClicked.emit(this.content);
    }
    // this.canPost = !this.customer.settings.auto_posting && ['ready', 'posting', 'posted'].includes(this.content.status);
    // this.initialWidth = this.width;
  }

  showPreview() {
    window.open(this.content.url, 'nil');
  }

  setContectCard(e: any, item: any) {
    this.content = item;
    this.cardClicked.emit(item);
  }


  getStyles(){
    this.customerProductStylesService.list().subscribe(resp => {
      this.filteredStyles = resp.data;
      this.mapStyles();
    });
  }

  mapStyles() {
    this.tabs = this.filteredStyles.map((product, i) => {
      return {
        product,
        styles: this.mapProdStyles(product),
        name: product.name,   // Using product name for tab name
        disabled: !product.available,  // Disable tab if product is not available
        content: `Content of ${product.name}` // Customize content if needed
      };
    });
    console.log("aa");
  }

  mapProdStyles(product) {
    return product.styles.map(s => {
      const imagepath = s.preview || s.image || '';
      const urlParts = imagepath.split('.');
      const extension = urlParts[urlParts.length - 1];
      return new Content({
        id: s.id,
        customer_content_calendar_style_id: s.customer_content_calendar_style_id,
        abbreviation: product.abbreviation,
        url: s.preview,
        style: s.effective_name,
        styleEffectiveName: s.effective_name,
        caption: this.transform(s.effective_name),
        media_type: extension === 'mp4' ? 'video' : 'image',
        is_style: true,
        selected: s.is_selected,
        is_vertical: s.is_vertical,
        ratio: s.ratio
      });
    });
  }
  transform(value: string): string {
    if (!value) { return value; }
    // Replace underscores with spaces
    const result = value.replace(/_/g, ' ');
    // Capitalize the first letter of each word
    return result.replace(/\b\w/g, char => char.toUpperCase());
  }

  selectStyle(content: Content, styles) {
    const selectedStyle = styles.find(template =>
      template.id === content.id
    );

    if (content.selected === true) {
      this.customerProductStylesService.destroy({ id: content.customer_content_calendar_style_id }).subscribe(resp => {
        selectedStyle.selected = false;
        this.message?.create('success', `Style successfully removed for the content calendar.`);
      });
    } else {
      this.customerProductStylesService.create({ style_id: content.id, product_category: selectedStyle.abbreviation }).subscribe(resp => {
        selectedStyle.selected = true;
        selectedStyle.customer_content_calendar_style_id = resp.id;
        this.message?.create('success', `Style successfully selected for the content calendar.`);
      });
    }
    this.cdr.detectChanges();
    this.changeStyle.emit(content);
  }

  resetDefaults(product){
    this.customerProductStylesService.reset(product.product.abbreviation).subscribe(resp => {
      this.message?.create('success', `Default styles have been successfully saved.`);
      this.getStyles();
    });
  }


}
