<div class="container">
  <div class="title-header">
    <h1 class="section-title title">
      <i nz-icon nzType="solution"></i>
      Audiences
    </h1>
  </div>

  <nz-tabset [nzSelectedIndex]="selectedIndex" (nzSelectedIndexChange)="onTabChange($event)">
    <nz-tab nzTitle="All">
      <div class="audience-widget">
        <app-audience-selector [filter]=""></app-audience-selector>
      </div>
    </nz-tab>
    <nz-tab nzTitle="Contacts">
      <div class="audience-widget">
        <app-audience-selector [filter]="'Contact'"></app-audience-selector>
      </div>
    </nz-tab>
    <nz-tab nzTitle="Leads">
      <app-leads-selector></app-leads-selector>
    </nz-tab>
  </nz-tabset>
</div>
