<ng-template #resourceslisting>
  <div class="container">
    <div class="title-header" *ngTemplateOutlet="listingTitle"></div>
    <div *ngIf="resourceLength">
      <ng-container *ngTemplateOutlet="Listings"></ng-container>
    </div>


    <div class="empty" *ngIf="!search && !resourceLength && showEmpty && ConfigListingTitle">
      <img class="internal-content" src="../../../assets/images/content.png">
      <h2 class="create-title mar-zero">
        You Don’t Have Any {{productType === 'market_report' ? 'Markets' : ConfigListingTitle?.title}}
      </h2>
      <!-- <p class="sub-title">
        Make new {{ConfigListingTitle.title}}
      </p> -->
      <button nz-button nzType="primary" *ngIf="productType === 'market_report'" (click)="createNewResource()">Create Market </button>
        <button nz-button nzType="primary" *ngIf="productType !== 'market_report'" (click)="createNewResource()">Let's Go </button>
    </div>
  </div>
</ng-template>
<ng-template #Listings>
  <div class="search">
    <nz-input-group [nzSuffix]="suffixIconSearch">
      <input type="text" nz-input placeholder="input search text" [ngModel]="searchText"
        (ngModelChange)='searchModelChanged.next($event)' />
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <i nz-icon nzType="search"></i>
    </ng-template>
  </div>
  <div *ngIf="isTestimonial; else nontestimomial">
  <nz-table [nzLoading]="loading" nzNoResult="No Market Report found!" nzTemplateMode>
    <thead (nzSortOrderChange)="sort($event)">
      <tr>
        <th nzShowSort nzColumnKey="attestant_name" [(nzSortFn)]="mapOfSort.region_name">
          Attestant's Name</th>
        <th class="text-center  center">Copy
          <!-- <span nz-tooltip nzTooltipTitle="This is the market the will be used for automatic posts" class="float-icon">
            <i nz-icon nzType="info-circle" nzTheme="outline" class="info-icon"></i>
          </span> -->
        </th>
        <th nzShowSort nzColumnKey="created_at" [(nzSortFn)]="mapOfSort.created_at">Created At</th>

        <th class="text-center wd-75"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let testimon of testimonies" class="cursor-pointer">
        <td>
          <nz-avatar nzSize="large" nzIcon="user" [nzSrc]="testimon?.image?.original"></nz-avatar>
          <a class="user-name">{{testimon.attestant_name}}</a>
        </td>

        <td>
          {{testimon.copy}}
        </td>

        <td>{{ testimon.created_at | date : "MM-dd-y"}}</td>
        <td class="text-center">
          <button *ngIf="!loading" nz-button nzType="default" (click)="selectTestimony(testimon)">
            Select</button>
        </td>
      </tr>
    </tbody>
  </nz-table>
  </div>

  <ng-template #nontestimomial>
  <nz-table nzNoResult="" nzTemplateMode>
    <thead (nzSortOrderChange)="sort($event)">
      <tr>
        <th nzShowSort nzColumnKey="region_name" [(nzSortFn)]="mapOfSort.region_name"> {{productType === 'market_report'
          ? 'Region Name' : 'Address'}}</th>
        <th *ngIf="productType === 'listing'">
          Listing Type
        </th>
        <th *ngIf="productType === 'listing'">
          Listing Status
        </th>
        <th class="text-center" nzShowSort nzColumnKey="created_at" [(nzSortFn)]="mapOfSort.created_at">Created At</th>
        <th class="text-center wd-130"></th>
      </tr>
    </thead>
    <tbody *ngIf="productType === 'market_report'">
      <tr *ngFor="let marketReport of marketreports" [ngClass]="{'selected': selectedResourceid === marketReport.id }">
        <td>{{getTitle(marketReport)}}</td>
        <td class="text-center">
          {{ marketReport.created_at | date : "MM-dd-y"}}
        </td>
        <td class="text-center">
          <button *ngIf="!loading" nz-button nzType="default" (click)="select(marketReport)">
            <i nz-icon nzType="check-circle" nzTheme="outline" class="selected-item "
              *ngIf="selectedResourceid === marketReport.id"></i>
            Select</button>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="productType === 'listing'">
      <tr *ngFor="let listing of listings">
        <td>
          <img [src]="listing.images_attributes[0] && listing.images_attributes[0].thumb" style="height: 50px">
          {{getTitle(listing)}}
        </td>
        <td>
          {{listing.listing_type}}
        </td>
        <td>
          {{listing.listing_status}}
        </td>

        <td class="text-center">
          {{ listing.created_at | date : "MM-dd-y"}}
        </td>
        <td class="text-center">
          <button nz-button nzType="default" *ngIf="!loading" (click)="select(listing)">
            <i nz-icon nzType="check-circle" nzTheme="outline" class="selected-item "
              *ngIf="selectedResourceid === listing.id"></i>
            Select</button>
        </td>
      </tr>
    </tbody>
  </nz-table>
  </ng-template>
  <div fxLayout="row" fxLayoutAlign="end start" *ngIf="resourceLength" class="mg-t-30">
    <nz-pagination
      class="pagination-right"
      [nzPageSize]="listingPagination.limit"
      [nzPageIndex]="listingPagination.page"
      [nzTotal]="listingPagination.total"
      (nzPageIndexChange)="didPage($event)">
    </nz-pagination>
  </div>
</ng-template>
<ng-template #listingTitle>
  <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="ConfigListingTitle && !type">
    <h1 class="resource-title">
      <i nz-icon nzType="{{ productType === 'listing' ? 'flag' : 'area-chart'}}"></i>
      {{ConfigListingTitle?.title}}
    </h1>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="create-cont" *ngIf="resourceLength">
    <button nz-button (click)="createNewResource();" nzType="default">Create New</button>
  </div>
</ng-template>

<nz-spin [nzSize]="'large'" *ngIf="loading; else notloading">
  <ng-container [ngTemplateOutlet]="resourceslisting"></ng-container>
</nz-spin>
<ng-template #notloading>
  <ng-container [ngTemplateOutlet]="resourceslisting"></ng-container>
</ng-template>
