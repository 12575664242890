import { OnInit, Directive } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication/authentication.service';
import { Observable, of } from 'rxjs';
import { DataResponse } from '../models/data-response/data-response';
import { Resource } from '../vos/resource/resource';
import { map } from 'rxjs/operators';

// @Injectable({
//   providedIn: 'root'
// })
export abstract class ResourceService<T extends Resource> {
  // public abstract endpoint: string;
  abstract list(): Observable<DataResponse<T[]>>;
  abstract show(id: number | string): Observable<DataResponse<T>>;
  abstract create(value: T): Observable<T>;
  abstract update(value: T): Observable<T>;
  abstract destroy(value: T): Observable<void>;
  // abstract exportContacts();
}

// @Injectable({
//   providedIn: 'root'
// })
@Directive()
export class CustomerResourceService<T extends Resource>
  extends ResourceService<T>
  implements OnInit {
  /**
   * Path uri.
   * @type {string}
   * @private
   */

  protected _uri = `${environment.api_url}`;
  protected _customerURI = `${environment.api_url}customers/`;
  /**
   * Url to endpoint api.
   * @type {string}
   */

  protected customer_id: number;
  public endpoint: string;
  public data_key: string;

  protected get customerEndpoint(): string {
    return !!this.authService.currentCustomerValue ? `${this.authService.currentCustomerValue?.id}/${this.endpoint}` : null;
  }
  private get object_key(): string {
    return `${this.data_key}`;
  }

  get resourceEndpoint(): string {
    return `${this._uri}${this.endpoint}`;
  }

  /**
   * Endpoint request headers.
   * @type {HttpHeaders}
   */
  protected headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  // protected csvheaders = new HttpHeaders({  Accept: 'application/csv', 'Content-Type': 'text/csv' });
  /**
   * Component constructor and DI injection point.
   * @param {HttpClient} http
   *
   */
  constructor(
    protected resource: new (vals: any) => T,
    protected http: HttpClient,
    protected authService: AuthenticationService
  ) {
    super();
    if (this.authService.currentCustomerValue) {
      this.customer_id = this.authService.currentCustomerValue?.id;
    } else {
      this.customer_id = null;
    }
  }

  ngOnInit() {
    this.authService.currentCustomer.subscribe(customer => {
      if (customer) {
        this.customer_id = customer.id;
      }
    });
  }

  customerEndpointFor(endpoint: string): string {
    return !!this.authService.currentCustomerValue ? `customers/${this.authService.currentCustomerValue?.id}/${endpoint}` : null;
  }
  listingEndpointFor(productname: string): string {
    return `products/${productname}/${this.endpoint}`;
  }
  /**
   * Pulls a list of T objects.
   * @returns {Observable<DataResponse<T[]>>}
   */
  public list(searchQuery?, type?: string): Observable<DataResponse<T[]>> {
    if (!this.customerEndpoint) {
      return of(null);
    }
    let url = `${this._customerURI}${this.customerEndpoint}${type ? type : ''}.json`;
    if (searchQuery) {
      url = url + searchQuery;
    }
    return this.http
      .get<DataResponse<T[]>>(
        url
      )
      .pipe(
        map(resp => {
          return resp;
        })
      );
  }

  public get(searchQuery?): Observable<any> {
    if (!this.customerEndpoint) {
      return of(null);
    }
    let url = `${this._customerURI}${this.customerEndpoint}`;
    if (searchQuery) {
      url = url + searchQuery;
    }
    return this.http
      .get<DataResponse<T[]>>(
        url
      )
      .pipe(
        map(resp => {
          // resp.data = resp.data ? resp.data.map(o => new this.resource(o)) : [];
          return resp;
        })
      );
  }
  public listingsList(productname: string, searchQuery?): Observable<DataResponse<T[]>> {
    let url = `${this._uri}${this.listingEndpointFor(productname)}.json`;
    if (searchQuery) {
      url = url + searchQuery;
    }
    return this.http
      .get<DataResponse<T[]>>(
        url
      )
      .pipe(
        map(resp => {
          resp.data = resp.data ? resp.data.map(o => new this.resource(o)) : [];
          return resp;
        })
      );
    //  products/:product_name/listings.json
  }
  public listFilter(content_id: number): Observable<DataResponse<T[]>> {
    if (!this.customerEndpoint) {
      return of(null);
    }
    return this.http
      .get<DataResponse<T[]>>(
        `${this._customerURI}${this.customerEndpoint}.json?q[contentable_type_eq]=MarketReport&q[contentable_id_eq]=${content_id}`
      )
      .pipe(
        map(resp => {
          resp.data = resp.data.map(o => new this.resource(o));
          return resp;
        })
      );
  }

  public listFrom(url: string, parameters?: Record<string, any>): Observable<DataResponse<T[]>> {
    const params_array = new Array;
    // tslint:disable-next-line: forin
    for (const key in parameters) {
      const value = parameters[key];
      if (value) {
        switch (typeof (value)) {
          case 'string':
          case 'number':
            params_array.push(`${key}=${value}`);
            break;
          default: // It's an array
            // tslint:disable-next-line: forin
            for (const x of value) { // Add array params
              params_array.push(`${key}=${x}`);
            }
            break;
        }
      }
    }
    const params_string = params_array.join('&');

    return this.http
      .get<DataResponse<T[]>>(
        `${this._uri}${url}.json` +
        `${params_array.length > 0 ? `?${params_string}` : ''}`
      )
      .pipe(
        map(resp => {
          resp.data = resp.data.map(o => new this.resource(o));
          return resp;
        })
      );
  }
  public listTags(url: string) {
    return this.http
      .get<DataResponse<T[]>>(
        `${this._uri}${url}.json`
      );
  }
  exportContacts(): Observable<any> {
    if (!this.customerEndpoint) {
      return of(null);
    }
    return this.http.get(
      `${this._customerURI}${this.customerEndpoint}.csv`,
      { responseType: 'text' }
    );
  }
  /**
   * Pulls a single T object.
   * @param {number | string} id to retrieve.
   * @returns {Observable<DataResponse<T>>}
   */
  show(id: number | string): Observable<DataResponse<T>> {
    if (!id) {
      return of(null);
    }
    const url = `${this._uri}${this.endpoint}/${id}.json`;
    return this.http.get<DataResponse<T>>(url).pipe(
      map(resp => {
        if(resp.data) {
          resp.data = new this.resource(resp.data);
        } else {
          resp.data = new this.resource(resp);
        }
        return resp;
      })
    );
  }

  /**
   * Creates a single T object.
   * @param {} value to create.
   * @returns {Observable<DataResponse<T>>}
   */
  create(value: any, options?: any): Observable<T> {
    if (!this.customerEndpoint) {
      return of(null);
    }
    let payload = JSON.stringify(value);
    if (this.object_key) {
      payload = JSON.stringify({ [this.object_key]: value })
    }

    let headers = this.headers;

    if (options?.headers) {
      Object.keys(options.headers).forEach(key => {
        headers = headers.set(key, options.headers[key]);
      });
    }

    return this.http
      .post<DataResponse<T>>(
        `${this._customerURI}${this.customerEndpoint}.json`,
        payload,
        { headers }
      )
      .pipe(map(resp => new this.resource(resp.data || resp)));
  }
  /**
   * Creates a single T object.
   * @param {} value to create.
   * @returns {Observable<DataResponse<T>>}
   */
  createCustom(value: any, customUrl): Observable<T> {
    return this.http
      .post<DataResponse<T>>(
        `${this._uri}${customUrl}`,
        JSON.stringify({ [this.object_key]: value }),
        { headers: this.headers }
      )
      .pipe(map(resp => new this.resource(resp.data || resp)));
  }
  createImage(value: any, endpoint?: string, url?: string): Observable<T> {
    let uri = '';
    if (url) {
      uri = `${this._uri}${url}`;
    } else {
      uri = endpoint ? `${this._customerURI}${this.authService.currentCustomerValue?.id}/${endpoint}` : `${this._uri}${this.endpoint}`;
    }

    return this.http
      .post<DataResponse<T>>(
        `${uri}.json`,
        JSON.stringify(value),
        { headers: this.headers }
      )
      .pipe(map(resp => new this.resource(resp.data || resp)));
  }
  updateImage(value: any, url?: string): Observable<T> {
    let uri = '';
    if (url) {
      uri = `${this._uri}${url}`;
    }

    return this.http
      .put<DataResponse<T>>(
        `${uri}.json`,
        JSON.stringify(value),
        { headers: this.headers }
      )
      .pipe(map(resp => new this.resource(resp.data || resp)));
  }
  /**
   * createWithoutObjectKey a single T object.
   * @param {} value to create.
   * @returns {Observable<DataResponse<T>>}
   */
  createWithoutObjectKey(value: any): Observable<T> {
    if (!this.customerEndpoint) {
      return of(null);
    }
    return this.http
      .post<DataResponse<T>>(
        `${this._customerURI}${this.customerEndpoint}.json`,
        value,
        { headers: this.headers }
      )
      .pipe(map(resp => new this.resource(resp.data)));
  }

  /**
   * createWithoutObjectKey a single T object.
   * @param {} value to create.
   * @returns {Observable<DataResponse<T>>}
   */
  createMultiple(values: T[]): Observable<T[]> {
    if (!this.customerEndpoint) {
      return of(null);
    }
    return this.http
      .post<DataResponse<T[]>>(
        `${this._customerURI}${this.customerEndpoint}/batch.json`,
        { [this.endpoint]: values },
        { headers: this.headers }
      )
      .pipe(map(resp => resp.data.map(r => new this.resource(r))));
  }

  /**
   * Updates a single T object.
   * @param {} value to update.
   * @returns {Observable<DataResponse<T>>}
   */
  update(value: T, customer_uri = false, options?: any): Observable<T> {
    if (!this.customerEndpoint) {
      return of(null);
    }
    const url = customer_uri ? `${this._customerURI}${this.customerEndpoint}/${value.id}.json`
      : `${this._uri}${this.endpoint}/${value.id}.json`;
      let payload = JSON.stringify(value);
      if (this.object_key) {
        payload = JSON.stringify({ [this.object_key]: value })
      }

    let headers = this.headers;

    if (options?.headers) {
      Object.keys(options.headers).forEach(key => {
        headers = headers.set(key, options.headers[key]);
      });
    }

    return this.http
      .put<DataResponse<T>>(url, payload, { headers })
      .pipe(map(resp => new this.resource(resp.data)));
  }

  putTo(value: T, endpoint: string, customer_uri = false, use_id = true): Observable<T> {
    if (!this.customerEndpoint) {
      return of(null);
    }
    const base_url = customer_uri ? `${this._customerURI}${this.customerEndpoint}` : `${this._uri}${this.endpoint}`;
    const end_url = use_id && value.id ? `${value.id}/${endpoint}.json` : `${endpoint}.json`;
    const url = `${base_url}/${end_url}`;
    return this.http.put<DataResponse<T>>(url, JSON.stringify({ [this.object_key]: value }), {
      headers: this.headers
    })
      .pipe(map(resp => new this.resource(resp.data)));
  }

  createPost(value: any, endpoint): Observable<T> {
    return this.http
      .post<DataResponse<T>>(
        `${this._customerURI}${this.authService.currentCustomerValue?.id}/${endpoint}.json`,
        JSON.stringify(value),
        { headers: this.headers }
      )
      .pipe(map(resp => new this.resource(resp.data || resp)));
  }
  /**
   * Destroys a single T object.
   * @param {number | string} id to destroy.
   * @returns {Observable<void>}
   */
  destroy(value: T): Observable<void> {
    const url = `${this._uri}${this.endpoint}/${value.id}.json`;
    return this.http.delete<void>(url, { headers: this.headers });
  }

  public listImages(searchQuery?): Observable<DataResponse<T[]>> {
    let url = `${this._uri}${this.endpoint}.json`;
    if (searchQuery) {
      url = url + searchQuery;
    }
    return this.http
      .get<DataResponse<T[]>>(
        url
      )
      .pipe(
        map(resp => {
          return resp;
        })
      );
  }
}
