<div class="full-page-container">
  <div class="form-wrapper">
    <nz-card class="form-panel" nzBordered="false" *ngIf="!completed">
      <!-- Logo & Title -->
      <div class="logo-container">
        <img src="assets/logos/blue-logo.png" alt="Logo" class="logo" style="width: 200px" />
      </div>
      <h2 class="title">Referral Rewards Program</h2>
      <p class="subtitle">
        Refer your friends and earn rewards! Fill in their details below.
      </p>

      <form #referralForm="ngForm">
        <!-- Gift Selection -->
        <div nz-row class="spac-row">
          <div nz-col nzSpan="24">
            <label class="field-label">Choose Your Reward</label>
            <nz-select [(ngModel)]="giftType" name="giftType" class="full-width" required>
              <nz-option nzValue="Gift Card" nzLabel="Amazon Gift Card"></nz-option>
              <nz-option nzValue="Free Month" nzLabel="1 Free Month of Service"></nz-option>
            </nz-select>
          </div>
        </div>
        <br>

        <!-- Your Name -->
        <div nz-row class="spac-row">
          <div nz-col nzSpan="24">
            <label class="field-label">Your Name</label>
            <input
              type="text"
              nz-input
              placeholder="Enter your name"
              [(ngModel)]="referred_by"
              #yourName="ngModel"
              name="yourName"
              required />
          </div>
          <div *ngIf="yourName.invalid && yourName.touched" class="error-message">
            <span *ngIf="yourName.errors?.required">Your name is required.</span>
          </div>
        </div>
        <br>

        <!-- Referrals List -->
        <div nz-row class="spac-row">
          <h3 class="section-title">Referral Information</h3>

          <div
            *ngFor="let ref of referrals; let i = index;"
            nz-col
            nzSpan="24"
            class="referral-box"
          >
            <!-- Show error alert if exists -->
            <nz-alert nzType="error" [nzMessage]="ref.error" *ngIf="ref.error"></nz-alert>

            <!-- Referral Name -->
            <label class="field-label">Name</label>
            <nz-input-group [nzPrefix]="prefixUser">
              <input
                type="text"
                nz-input
                placeholder="Enter referral name"
                [(ngModel)]="ref.name"
                #refName="ngModel"
                name="refName{{i}}"
                required />
            </nz-input-group>
            <ng-template #prefixUser><span nz-icon nzType="user"></span></ng-template>
            <div *ngIf="refName.invalid && refName.touched" class="error-message">
              <span *ngIf="refName.errors?.required">Referral name is required.</span>
            </div>

            <!-- Referral Email -->
            <label class="field-label">Email</label>
            <nz-input-group [nzPrefix]="prefixEmail">
              <input
                type="email"
                nz-input
                placeholder="Enter referral email"
                [(ngModel)]="ref.email"
                #refEmail="ngModel"
                name="refEmail{{i}}"
                required
                email />
            </nz-input-group>
            <ng-template #prefixEmail><span nz-icon nzType="mail"></span></ng-template>
            <div *ngIf="refEmail.invalid && refEmail.touched" class="error-message">
              <span *ngIf="refEmail.errors?.required">Referral email is required.</span>
              <span *ngIf="refEmail.errors?.email">Invalid email format.</span>
            </div>

            <!-- Referral Phone -->
            <label class="field-label">Phone</label>
            <nz-input-group [nzPrefix]="prefixPhone">
              <input
                type="text"
                nz-input
                placeholder="Enter referral phone"
                [(ngModel)]="ref.phone"
                #refPhone="ngModel"
                name="refPhone{{i}}"
                required
                pattern="^[0-9]{10,15}$" />
            </nz-input-group>
            <ng-template #prefixPhone><span nz-icon nzType="phone"></span></ng-template>
            <div *ngIf="refPhone.invalid && refPhone.touched" class="error-message">
              <span *ngIf="refPhone.errors?.required">Referral phone is required.</span>
              <span *ngIf="refPhone.errors?.pattern">Invalid phone number (10-15 digits only).</span>
            </div>

            <!-- Delete Button -->
            <button nz-button nzType="link" class="delete-btn" (click)="removeRef(i)" *ngIf="i != 0">
              <span nz-icon nzType="delete"></span> Remove
            </button>
          </div>

          <!-- Add More Button -->
          <div nz-col nzSpan="24" class="spac-row center-text">
            <button nz-button nzType="dashed" (click)="addRef()" class="add-btn">
              <span nz-icon nzType="plus"></span> Add More Referrals
            </button>
          </div>
        </div>

        <!-- Submit & Cancel Buttons -->
        <div class="button-container">
          <button nz-button nzType="primary" (click)="handleSubmitReferral()" [disabled]="referralForm.invalid">
            Submit
          </button>
        </div>
      </form>
    </nz-card>



    <nz-result
      style="background: white"
      *ngIf="completed"
      nzStatus="success"
      nzTitle="Referral Submitted Successfully!"
      nzSubTitle="Thank you for your referral! We appreciate your support. Our team will review the details and follow up shortly."
    >
      <div nz-result-extra>
        <button nz-button nzType="primary" (click)="navigateToReferrals()">Refer Another Friend</button>
      </div>
    </nz-result>
  </div>
</div>
